import React, {useState} from "react";
import {Box, DialogContent, Grid, Typography} from "@mui/material";
import {PASSWORD_RESET_SCHEMA} from "src/features/session/session-validation";
import {useEntityApi} from "src/features/entity/entity-hooks";
import {PASSWORD_RESET} from "src/api/api-schemas";
import {SimpleYupFormDialog} from "src/packages/react-hook-form-mui-yup-helpers/simple-yup-form-dialog";
import {YupField} from "src/packages/react-hook-form-mui-yup-helpers";

export default function PasswordResetForm({
  visible,
  uid,
  token,
  open,
  onClose,
  onSubmit,
}) {
  const [data, setData] = useState({
    uid,
    token,
    new_password1: '',
    new_password2: '',
  });

  const passwordResetApi = useEntityApi(PASSWORD_RESET);

  const savePassword = async (validatedData) => {
    await passwordResetApi.post(
      `/api/sendemeldung/auth/password/reset/confirm/`,
      validatedData,
    );
    onSubmit && onSubmit();
  };

  return (
    <SimpleYupFormDialog
      id="password_reset_confirm"
      title="Passwort zurücksetzen"
      open={!!open}
      data={data}
      onClose={onClose}
      submit={savePassword}
      schema={PASSWORD_RESET_SCHEMA}
      saveCaption="Passwort speichern"
      cancelCaption="Abbrechen"
      maxWidth="xl"
    >
      <DialogContent>
        <Typography gutterBottom>
          Bitte wählen Sie ein neues Passwort für Ihren Sendemeldung.de-Zugang.
        </Typography>

        <Box p={2} mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <YupField name="new_password1" variant="outlined"/>
            </Grid>
            <Grid item xs={12}>
              <YupField name="new_password2" variant="outlined"/>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </SimpleYupFormDialog>
  );
}
