import React, {useEffect, useState} from "react";
import {Router} from "@gatsbyjs/reach-router";
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {StyledEngineProvider} from "@mui/material/styles";
import {LocalizationProvider} from '@mui/x-date-pickers';
import {useSelector} from "react-redux";
import {isLoggedIn} from "src/features/session";
import {navigate} from "gatsby";
import {Welcome} from "src/components/welcome";
import PasswordResetForm from "src/components/welcome/PasswordResetForm";
import {useHasMounted} from "src/packages/has-mounted-hook";

function PasswordReset({
  uid,
  token
}) {
  const loggedIn = useSelector(isLoggedIn);

  const [open, setOpen] = useState(!!(uid && token && !loggedIn));
  const [hadPasswordReset, setHadPasswordReset] = useState(false);

  const hasMounted = useHasMounted();

  return (
    <Welcome
      hadPasswordReset={hadPasswordReset}
    >
      {uid && token && hasMounted ? (
        <PasswordResetForm
          uid={uid}
          token={token}
          open={open}
          onClose={() => setOpen(false)}
          onSubmit={() => setHadPasswordReset(true)}
        />
      ) : null}
    </Welcome>
  );
}

const LoggedOffRoute = ({
  component: Component,
  ...rest
}) => {
  const loggedIn = useSelector(isLoggedIn);

  useEffect(() => {
    if (loggedIn) {
      navigate("/");
    }
  }, [loggedIn]);

  return (
    <Component {...rest} />
  );
};

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="de">
        <Router basepath="/passwort_zuruecksetzen">
          <LoggedOffRoute path="/:uid/:token" component={PasswordReset}/>
          {/*<LoggedOffRoute default component={NotFoundPage}/>*/}
        </Router>
      </LocalizationProvider>
    </StyledEngineProvider>
  );
};

export default App;
